<template>
  <div>
    <!-- Stats cards -->
    <div class="row">
      <div
        class="col-md-6 col-xl-4"
        v-for="stats in statsCards"
        :key="stats.title"
      >
        <stats-card>
          <div
            class="icon-big text-center"
            :class="`icon-${stats.type}`"
            slot="header"
          >
            <i :class="stats.icon"></i>
          </div>
          <div class="numbers" slot="content">
            <p>{{ stats.title }}</p>
            {{ stats.value }}
          </div>
          <div class="stats" slot="footer">
            <a :href="stats.link">
              <i :class="stats.footerIcon"></i> {{ stats.footerText }}
            </a>
          </div>
        </stats-card>
      </div>
    </div>

    <!-- Selector de fecha con estilos y botón -->
    <div class="row">
      <div class="col-4">
        <div class="d-flex align-items-center">
          <input
            type="date"
            v-model="selectedDate"
            class="form-control me-2"
            id="fecha"
          />
          <button @click="fetchData" class="btn btn-default">Buscar</button>
        </div>
      </div>
    </div>

    <!-- Título de la tabla con última actualización -->
    <div class="row">
      <div class="col-4">
        <card-formularios :title="table.title" :subTitle="table.subTitle">
          <div class="mb-2">
            <!-- Mostrar la fecha y hora de la última actualización -->
            <small>Última actualización: {{ lastUpdateTime }}</small>
          </div>
          <div slot="raw-content" class="table-responsive">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>Departamento</th>
                  <th>Total Solicitudes</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(empleo, index) in empleosPorDepartamento" :key="index">
                  <td>{{ empleo.departamento }}</td>
                  <td class="text-right">{{ empleo.totalEmpleos }}</td>
                </tr>
                <tr>
                  <td><strong>Total</strong></td>
                  <td class="text-right">
                    <strong>
                      {{
                        empleosPorDepartamento.reduce(
                          (acc, empleo) => acc + empleo.totalEmpleos,
                          0
                        )
                      }}
                    </strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </card-formularios>
      </div>
    </div>


  </div>
</template>

<script>
import { StatsCard } from "@/components/index";
import CardFormularios from "@/components/Cards/Card.vue";
import solicitudesService from "@/services/solicitud_empleo_service.js";
import membresiasService from '@/services/membresias_service';
import matrimoniosService from '@/services/matrimonios_service';

export default {
  components: {
    StatsCard,
    CardFormularios,
  },

  data() {
    return {
      selectedDate: new Date().toISOString().split('T')[0], // Establece la fecha actual por defecto
      table: {
        title: "Solicitudes de Empleo por Departamento",
        subTitle: "HIGUERTROPIC HONDURAS, S. DE. R.L.",
      },
      empleosPorDepartamento: [],
      lastUpdateTime: "", // Almacena la fecha y hora de la última actualización
      totalRegistrants: 0,
      totalMatrimonios: 0,
      totalSolicitudes:0,
      statsCards: [
        {
          type: "warning",
          icon: "ti-user",
          title: "Membresias Activas",
          value: 0,
          footerText: "Ingresar Membresia",
          footerIcon: "ti-arrow-right",
          link: '/inscripcion-membresia',
        },
        {
          type: "danger",
          icon: "ti-heart",
          title: "Matrimonios Activos",
          value: 0,
          footerText: "Ingresar Matrimonio",
          footerIcon: "ti-arrow-right",
          link: '/inscripcion-matrimonio',
        },
        {
          type: "success",
          icon: "ti-briefcase",
          title: "Solicitudes Activas",
          value: 0,
          footerText: "Ingresar Solicitud",
          footerIcon: "ti-arrow-right",
          link: '/inscripcion-solicitud-empleo',
        }
      ],
    };
  },

  created() {
    this.updateLastUpdateTime(); // Actualiza la hora de la última actualización al cargar
  },

  mounted() {
    // Para total de membresias
this.fetchAndUpdateCard(membresiasService.fetchMembresias, 0);

// Para total de matrimonios
this.fetchAndUpdateCard(matrimoniosService.fetchMatrimonios, 1);

// Para total de solicitudes
this.fetchAndUpdateCard(solicitudesService.fetchSolicitudEmpleo, 2);



  },

  methods: {

    async fetchAndUpdateCard(serviceMethod, cardIndex) {
      try {
        const response = await serviceMethod();
        const totalItems = response.data.data.length;
        this.animateCounter(cardIndex, totalItems); // Iniciar la animación del contador
      } catch (error) {
        console.error(`Error fetching data for card ${cardIndex}:`, error);
        this.updateStatsCard(cardIndex, 0); // Mostrar 0 en caso de error
      }
    },

    updateStatsCard(cardIndex, value) {
      // Usar Vue.set para garantizar reactividad
      this.$set(this.statsCards[cardIndex], 'value', value);
    },
    animateCounter(cardIndex, finalValue) {
      let currentValue = 0;
      const increment = Math.ceil(finalValue / 100); // Ajustar incremento si es necesario
      const interval = setInterval(() => {
        if (currentValue < finalValue) {
          currentValue += increment;
          if (currentValue > finalValue) currentValue = finalValue; // Evitar que exceda el valor final
          this.updateStatsCard(cardIndex, currentValue);
        } else {
          clearInterval(interval); // Detener el intervalo cuando se alcance el valor final
        }
      }, 50); // Ajustar velocidad según sea necesario
    },


    async fetchData() {
      if (!this.selectedDate) {
        alert("Por favor selecciona una fecha");
        return;
      }

      try {
        const fechaFiltro = this.selectedDate;
        const responseEmpleos = await solicitudesService.getEmpleosporDepartamento(
          fechaFiltro
        );

        const empleos = responseEmpleos.data.data;

        const resumen = empleos.reduce((acc, empleo) => {
          const departamento =
            empleo.attributes.DatosGenerales.Departamento?.data?.attributes
              ?.Nombre || "Sin Departamento";

          if (!acc[departamento]) {
            acc[departamento] = 0;
          }
          acc[departamento] += 1;

          return acc;
        }, {});

        this.empleosPorDepartamento = Object.entries(resumen).map(
          ([departamento, totalEmpleos]) => ({
            departamento,
            totalEmpleos,
          })
        );

        // Actualizar la fecha y hora de la última actualización
        this.updateLastUpdateTime();
      } catch (error) {
        console.error("Error al obtener los empleos:", error);
      }
    },

    updateLastUpdateTime() {
      const now = new Date();
      this.lastUpdateTime = `${now.toLocaleTimeString()}`;
    },
  },
};
</script>

<style>

</style>




